import {
	ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react';
import { Additional, AdditionalGroup, Product } from '../../shared/entities';
import { AppError } from '../../shared/errors';
import { ProductRepository } from '../../shared/repositories';

type Props = {
	children: ReactNode;
	productRepository: ProductRepository;
};

type PropsProductContext = {
	state: {
		product: Product | null;
		isLoading: boolean;
		error: AppError | null;
		dialogError: AppError | null;
		quantity: number;
		observations: string;
		totalPrice: number;
		multipleChoices: Map<Additional, number>;
		singleChoices: Map<AdditionalGroup, Additional | null>;
	};
	loadProduct: (id: number) => void;
	incrementQuantity: () => void;
	decrementQuantity: () => void;
	onObservationChange: (value: string) => void;
	incrementAdditionalQuantity: (
		additional: Additional,
		group: AdditionalGroup
	) => void;
	decrementAdditionalQuantity: (
		additional: Additional,
		group: AdditionalGroup
	) => void;
	selectAdditional: (additional: Additional, group: AdditionalGroup) => void;
	totalAdditionalGroupSelected: (group: AdditionalGroup) => number;
	selectedAdditionals: () => Map<Additional, number>;
	limitAdditionalReached: (
		additional: Additional,
		group: AdditionalGroup
	) => boolean;
	requiredAdditionalsSelected: () => boolean;
	closeDialogError: () => void;
	setDialogError: (error: AppError) => void;
};

const DEFAULT_VALUE = {
	state: {
		product: null as Product | null,
		isLoading: true,
		error: null as AppError | null,
		dialogError: null as AppError | null,
		quantity: 1,
		observations: '',
		totalPrice: 0,
		multipleChoices: new Map<Additional, number>(),
		singleChoices: new Map<AdditionalGroup, Additional | null>(),
	},
	loadProduct: (id: number) => {},
	incrementQuantity: () => {},
	decrementQuantity: () => {},
	onObservationChange: (value: string) => {},
	decrementAdditionalQuantity: (
		additional: Additional,
		group: AdditionalGroup
	) => {},
	incrementAdditionalQuantity: (
		additional: Additional,
		group: AdditionalGroup
	) => {},
	selectAdditional: (additional: Additional, group: AdditionalGroup) => {},
	totalAdditionalGroupSelected: (group: AdditionalGroup) => 0,
	selectedAdditionals: () => new Map<Additional, number>(),
	limitAdditionalReached: (additional: Additional, group: AdditionalGroup) =>
		false,
	requiredAdditionalsSelected: () => false,
	closeDialogError: () => {},
	setDialogError: (error: AppError) => {},
};

export const ProductContext = createContext<PropsProductContext>(DEFAULT_VALUE);

export const useProduct = (): PropsProductContext => useContext(ProductContext);

export const ProductContextProvider = ({
	children,
	productRepository,
}: Props) => {
	const [state, setState] = useState(DEFAULT_VALUE.state);

	async function loadProduct(id: number) {
		setState({ ...state, error: null, isLoading: true });
		try {
			const product = await productRepository.getProduct(id);
			const groups = product.additionalGroups;
			for (const group of groups.filter((g) => !g.isSingleChoice)) {
				for (const additional of group.additionals) {
					state.multipleChoices.set(additional, 0);
				}
			}
			for (const group of groups.filter((g) => g.isSingleChoice)) {
				state.singleChoices.set(group, null);
			}
			setState({
				...state,
				product,
				isLoading: false,
				totalPrice: product.price,
			});
		} catch (error) {
			setState({ ...state, error: error as AppError, isLoading: false });
		}
	}

	useEffect(() => {
		if (!state.product) return;
		const additionals = selectedAdditionals();
		const additionalsTotal = Array.from(additionals).reduce(
			(acc, [additional, quantity]) => acc + additional.price * quantity,
			0
		);
		const total = (state.product!.price + additionalsTotal) * state.quantity;
		setState({ ...state, totalPrice: total });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.quantity, state.multipleChoices, state.singleChoices]);

	useEffect(() => {
		return () => {
			state.multipleChoices.clear();
			state.singleChoices.clear();
		};
	}, []);

	function incrementQuantity(): void {
		if (state.quantity === 99) return;
		const quantity = state.quantity + 1;
		setState({ ...state, quantity });
	}

	function decrementQuantity(): void {
		if (state.quantity === 1) return;
		const quantity = state.quantity - 1;
		setState({ ...state, quantity });
	}

	function onObservationChange(value: string): void {
		setState({ ...state, observations: value });
	}

	function limitAdditionalReached(
		additional: Additional,
		group: AdditionalGroup
	) {
		if (group.isSingleChoice) {
			return state.singleChoices.get(group) !== null;
		}
		if (!group.repeatable) {
			const total = Array.from(state.multipleChoices)
				.filter(([additionalEntry]) => additionalEntry.id === additional.id)
				.reduce((acc, [, quantity]) => acc + quantity, 0);
			if (total > 0) return true;
		}
		return (
			Array.from(state.multipleChoices)
				.filter(([additional]) => group.additionals.includes(additional))
				.reduce((acc, [, quantity]) => acc + quantity, 0) >= group.max
		);
	}

	function incrementAdditionalQuantity(
		additional: Additional,
		group: AdditionalGroup
	) {
		const current = state.multipleChoices.get(additional)!;
		if (current >= group.max || limitAdditionalReached(additional, group))
			return;
		const multipleChoices = state.multipleChoices.set(additional, current + 1);
		setState({ ...state, multipleChoices: new Map(multipleChoices) });
	}

	function decrementAdditionalQuantity(
		additional: Additional,
		group: AdditionalGroup
	) {
		const current = state.multipleChoices.get(additional)!;
		if (current <= 0) return;
		const multipleChoices = state.multipleChoices.set(additional, current - 1);
		setState({ ...state, multipleChoices: new Map(multipleChoices) });
	}

	function selectAdditional(additional: Additional, group: AdditionalGroup) {
		const isSelected = state.singleChoices.get(group) === additional;
		const singleChoices = state.singleChoices.set(
			group,
			isSelected ? null : additional
		);
		setState({ ...state, singleChoices: new Map(singleChoices) });
	}

	function totalAdditionalGroupSelected(group: AdditionalGroup): number {
		if (group.isSingleChoice) {
			return state.singleChoices.get(group) ? 1 : 0;
		}
		return Array.from(state.multipleChoices)
			.filter(([additional, quantity]) =>
				group.additionals.includes(additional)
			)
			.map(([additional, quantity]) => quantity)
			.reduce((acc, quantity) => acc + quantity, 0);
	}

	function selectedAdditionals(): Map<Additional, number> {
		const additionals = new Map<Additional, number>();

		const multipleChoices = Array.from(state.multipleChoices).filter(
			([additional, quantity]) => quantity > 0
		);
		const singleChoices = Array.from(state.singleChoices)
			.filter(([group, additional]) => additional !== null)
			.map(([group, additional]) => ({ additional: additional!, quantity: 1 }));

		for (const [additional, quantity] of multipleChoices) {
			additionals.set(additional, quantity);
		}

		for (const { additional, quantity } of singleChoices) {
			additionals.set(additional, quantity);
		}

		return additionals;
	}

	function requiredAdditionalsSelected(): boolean {
		return state
			.product!.additionalGroups.filter((group) => group.required)
			.every((group) => totalAdditionalGroupSelected(group) >= group.min);
	}

	function closeDialogError() {
		setState({ ...state, dialogError: null });
	}

	function setDialogError(error: AppError) {
		setState({ ...state, dialogError: error });
	}

	return (
		<ProductContext.Provider
			value={{
				state,
				loadProduct,
				incrementQuantity,
				decrementQuantity,
				onObservationChange,
				incrementAdditionalQuantity,
				decrementAdditionalQuantity,
				selectAdditional,
				totalAdditionalGroupSelected,
				selectedAdditionals,
				limitAdditionalReached,
				requiredAdditionalsSelected,
				closeDialogError,
				setDialogError,
			}}
		>
			{children}
		</ProductContext.Provider>
	);
};
