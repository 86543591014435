import { Additional } from '../../shared/entities';

export class ProductSelection {
	constructor(
		public id: number,
		public name: string,
		public price: number,
		public additionals: Map<Additional, number>,
		public quantity: number,
		public observations: string,
		public tipPercent: number = 0
	) {}

	get tip(): number {
		return this.total * (this.tipPercent / 100);
	}

	get total(): number {
		const totalAdditionals = Array.from(this.additionals).reduce(
			(total, [additional, quantity]) => total + additional.price * quantity,
			0
		);
		return (this.price + totalAdditionals) * this.quantity;
	}

	static fromJson(json: any): ProductSelection {
		return new ProductSelection(
			json.id,
			json.name,
			json.price,
			new Map(
				json.additionals.map((additionalJson: any) => [
					Additional.fromJson(additionalJson),
					additionalJson.quantity,
				])
			),
			json.quantity,
			json.observations,
			json.tipPercent
		);
	}

	toJson(): any {
		return {
			id: this.id,
			name: this.name,
			price: this.price,
			additionals: Array.from(this.additionals).map(
				([additional, quantity]) => ({
					id: additional.id,
					groupId: additional.groupId,
					name: additional.name,
					description: additional.description,
					price: additional.price,
					position: additional.position,
					quantity: quantity,
				})
			),
			quantity: this.quantity,
			observations: this.observations,
			tipPercent: this.tipPercent,
		};
	}
}
