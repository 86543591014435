import { Address } from '../entities/address';
import { UnexpectedError, ZipCodeNotFoundError } from '../errors';
import { HttpBadRequestError } from '../http_client';
import { HttpClient } from '../http_client/http_client';

export class AddressRepository {
	constructor(private readonly httpClient: HttpClient) {}

	async getAddressByZipCode(zipCode: string): Promise<Address> {
		try {
			const json = await this.httpClient.get(`/address/zipcode/${zipCode}`);
			return Address.fromJson(json);
		} catch (error) {
			if (error instanceof HttpBadRequestError) {
				if (error.name === 'ZipCodeNotFound') {
					throw new ZipCodeNotFoundError();
				}
			}
			throw new UnexpectedError();
		}
	}
}
