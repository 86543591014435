export class Product {
	id: number;
	name: string;
	price: number;
	additionalGroups: AdditionalGroup[];
	imageUrl?: string;
	observation?: string;
	groupId: number | null;

	static fromJson(json: any): Product {
		return {
			id: json.id,
			name: json.name,
			price: json.price,
			additionalGroups: (json.additionalGroups ?? []).map((item: any) =>
				AdditionalGroup.fromJson(item)
			),
			imageUrl: json.imageUrl,
			observation: json.observation,
			groupId: json.categoryId,
		};
	}
}

export class AdditionalGroup {
	constructor(
		public id: number,
		public name: string,
		public position: number,
		public required: boolean,
		public repeatable: boolean,
		public min: number,
		public max: number,
		public additionals: Additional[]
	) {}

	static fromJson(json: any): AdditionalGroup {
		return new AdditionalGroup(
			json.id,
			json.name,
			json.position,
			json.required,
			json.repeatable,
			json.min,
			json.max,
			(json.additionals ?? [])
				.map((item: any) => ({ ...item, groupId: json.id }))
				.map((item: any) => Additional.fromJson(item))
		);
	}

	get isSingleChoice(): boolean {
		return this.min === 1 && this.max === 1;
	}
}

export class Additional {
	id: number;
	groupId: number;
	name: string;
	price: number;
	position: number;
	description: string;

	static fromJson(json: any): Additional {
		return {
			id: json.id,
			groupId: json.groupId,
			name: json.name,
			price: json.price ? json.price : 0,
			position: json.position,
			description: json.description,
		};
	}
}
